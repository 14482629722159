.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-layout {
  height: 100vh;
}

.footer-background {
  height: 3em;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
/*  background-color: black;*/
  text-align: center;
  z-index: 0;
}

.App .ant-card-body {
  padding: 12px;
}

.App .vol-slider {
  padding: 12px 0 8px 0;
}

.logo-container {
  height: 70px;
  margin-top: 8px;
  padding: 2px;
  border: 2px solid white;
  border-radius: 50%;
}

.rc-logo {
  width: auto;
  max-height: 100%;
  border-radius: 50%;
}

.shader-trigger {
  z-index: 999999;
}

.white-noise {
  height: 100%;
}

.playback-card {
  position: absolute;
  width: 100%;
  height: 100%;
}

.playback-card .play-card-icon {
  position: relative;
  top: calc(50% - 64px);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 0.9;
}

.playback-card .stop-card-icon {
  position: relative;
  top: calc(50% - 64px);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 0.9;
}

.play-container {
}

.play-stop {
  position: absolute;
  bottom: 1.5em;
  transform: translate(-50%, 0);
  z-index: 999;
}

.play-icon {
  margin-left: 0.15em;
  transform: translate(0, -0.09em);
}

.stop-icon {
  margin-left: -0.01em;
  transform: translate(0, -0.17em);
}

.sound-description {
  margin: 1em;
  padding: 1em;
  color: white;
  bottom: 4em;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
/*  position: absolute;*/
}

.left-freq {
  margin: 8px;
  text-align: right;
}

.right-freq {
  margin: 8px;
  text-align: left;
}

.description-modal .ant-modal-header {
  background-color: rgba(31, 31, 31, 0);
}

.description-modal .ant-modal-content {
  background-color: rgba(31, 31, 31, 0.95);
}

.feedback-modal {

}

.feedback-modal .ant-modal-header {
/*  background-color: rgba(34, 40, 49, 0);*/
}

.feedback-modal .ant-modal-content {
/*  background-color: rgba(34, 40, 49, 1);*/
}

.feedback-modal .ant-modal-close {
  top: 7px;
  right: 7px;
}

.icon-wrapper {
  position: relative;
  padding: 0px 22px;
}

.icon-wrapper .anticon {
  position: absolute;
  top: 8px;
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 1;
}

.icon-wrapper .icon-wrapper-active {
  color: rgba(0, 0, 0, 0.45);
}

.icon-wrapper .anticon:first-child {
  left: 0;
}

.icon-wrapper .anticon:last-child {
  right: 0;
}

.audio-icon {
  padding-block: 4px;
}